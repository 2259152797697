import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import styles from '../styles/Login.module.css';
import { api } from '../services/api';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (getCookie('access_token')) {
      router.push('/dashboard');
    }
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email || !password) {
      return;
    }

    setLoading(true);
    try {
      const auth = await axios.post('https://rest-api.gcp.chattersocial.io/auth/login', {
        provider: 'email',
        payload: {
          email,
          password,
        },
      });

      if (auth.data.error) {
        setError(auth.data.error);
        setLoading(false);
        return;
      }

      if (!auth.data.access_token || !auth.data.refresh_token) {
        setError("Doesn't have an access");
        setLoading(false);
        return;
      }

      setCookie('access_token', auth.data.access_token, {
        sameSite: true,
        secure: true,
      });
      setCookie('refresh_token', auth.data.refresh_token, {
        sameSite: true,
        secure: true,
      });

      const user = await api.auth.me();

      if (!user?.pro_subscribed) {
        setError("Doesn't have an access");
        deleteCookie('access_token');
        deleteCookie('refresh_token');
        setLoading(false);
        return;
      }

      router.push('/dashboard');
    } catch (e) {
      setError('Something went wrong');
    }
    setLoading(false);
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginBox}>
        <img src="/images/logo.png" alt="Chatter Social" className={styles.logo} />
        <form onSubmit={handleLogin} className={styles.loginForm}>
          <h2 className={styles.heading}>Login to Chatter Social</h2>
          {error && <p className={styles.error}>{error}</p>}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.inputField}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.inputField}
          />
          <button disabled={loading} type="submit" className={styles.loginButton}>
            {loading ? '...' : 'Login'}
          </button>
        </form>
        <div className={styles.footer}>
          <a href="#" className={styles.forgotPassword}>
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
}
